import axios, {
  villaAzulApi,
  rechargesApi,
  rechargesApiV2,
  shopApi,
} from 'src/utils/axios'

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          !error.response.config.url.includes('change_password')
        ) {
          this.setSession(null)

          if (onLogout) {
            onLogout()
          }
        }

        return Promise.reject(error)
      }
    )
  }

  handleAuthentication() {
    const accessToken = this.getAccessToken()

    if (!accessToken) {
      return
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken)
    } else {
      this.setSession(null)
    }
  }

  loginWithEmailAndPassword = (username, password) =>
    new Promise((resolve, reject) => {
      const params = {
        username,
        password,
      }
      axios
        .post('/auth/login', params)
        .then((response) => {
          if (
            response.data.usuarioDB &&
            response.data.usuarioDB.role.includes('ADMIN')
          ) {
            this.setSession(response.data.token)
            resolve(response.data.usuarioDB)
          } else {
            reject(response.data.error)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })

  changePassword = (oldPassword, newPassword) =>
    new Promise((resolve, reject) => {
      const params = {
        oldPassword,
        password: newPassword,
      }
      const config = {
        headers: { Authorization: `Bearer ${this.getAccessToken()}` },
      }
      axios
        .patch('/profile/change_password', params, config)
        .then((response) => {
          if (response.data) {
            resolve(response)
          } else {
            reject(response.data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })

  loginInWithToken = () =>
    new Promise((resolve, reject) => {
      const config = {
        headers: { Authorization: `Bearer ${this.getAccessToken()}` },
      }
      axios
        .get('/profile', config)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.data.error)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })

  logout = () => {
    this.setSession(null)
  }

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken)
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
      villaAzulApi.defaults.headers.common.Authorization = `Bearer ${accessToken}`
      rechargesApi.defaults.headers.common.Authorization = `Bearer ${accessToken}`
      rechargesApiV2.defaults.headers.common.Authorization = `Bearer ${accessToken}`
      shopApi.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
      localStorage.removeItem('accessToken')
      delete axios.defaults.headers.common.Authorization
      delete villaAzulApi.defaults.headers.common.Authorization
      delete rechargesApi.defaults.headers.common.Authorization
      delete rechargesApiV2.defaults.headers.common.Authorization
      delete shopApi.defaults.headers.common.Authorization
    }
  }

  getAccessToken = () => localStorage.getItem('accessToken')

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false
    }

    return true
  }

  isAuthenticated = () => !!this.getAccessToken()
}

const authService = new AuthService()

export default authService
