/* eslint-disable import/prefer-default-export */
export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN',
}

export const RECHARGE_TYPES = [
  {
    id: 'cubacel',
    name: 'Cubacel',
  },
  {
    id: 'nauta',
    name: 'Nauta',
  },
]

export const APPS = { shop: 'tienda', recharges: 'recargas' }
export const INITIAL_PAGE = 0
export const DEFAULT_LIMIT = 10
export const IMAGES_URL = 'https://api.shop.detooo.com/uploads/'
