import React from 'react'
import Lottie from 'react-lottie'
import { Box, makeStyles } from '@material-ui/core'
import animationData from './Loading_200x330.json'

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    padding: theme.spacing(3),
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 2000,
  },
}))

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

function SplashScreenAnimated() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent="center" mb={6}>
        <Lottie options={defaultOptions} />
      </Box>
    </div>
  )
}

export default SplashScreenAnimated
