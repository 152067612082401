import React, { useRef } from 'react'
import { IconButton, SvgIcon, Tooltip } from '@material-ui/core'
import { Moon as MoonIcon, Sun as SunIcon } from 'react-feather'
import useSettings from 'src/hooks/useSettings'
import { useTranslation } from 'react-i18next'

function Settings() {
  const ref = useRef(null)
  const { t } = useTranslation('translation')
  const { settings, saveSettings } = useSettings()

  const handleChangeTheme = () => {
    const th = settings.theme === 'LIGHT' ? 'ONE_DARK' : 'LIGHT'
    saveSettings({ theme: th })
  }

  return (
    <>
      <Tooltip title={t('Theme')}>
        <IconButton color="inherit" onClick={handleChangeTheme} ref={ref}>
          <SvgIcon fontSize="small">
            {settings.theme === 'LIGHT' ? <MoonIcon /> : <SunIcon />}
          </SvgIcon>
        </IconButton>
      </Tooltip>
    </>
  )
}

export default Settings
