import axios from 'src/utils/axios'
import authService from 'src/services/authService'

export const LOGIN_REQUEST = '@account/login-request'
export const LOGIN_SUCCESS = '@account/login-success'
export const LOGIN_FAILURE = '@account/login-failure'
export const SILENT_LOGIN = '@account/silent-login'
export const LOGOUT = '@account/logout'
export const REGISTER = '@account/register'
export const UPDATE_PROFILE = '@account/update-profile'

export function login(username, password) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST })

      const user = await authService.loginWithEmailAndPassword(
        username,
        password
      )

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user,
        },
      })
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE })
      throw error
    }
  }
}

export function setUserData(user) {
  return (dispatch) =>
    dispatch({
      type: SILENT_LOGIN,
      payload: {
        user,
      },
    })
}

export function logout() {
  return async (dispatch) => {
    authService.logout()

    dispatch({
      type: LOGOUT,
    })
  }
}

export function register() {
  return true
}

export function updateProfile(update) {
  const config = {
    headers: { Authorization: `Bearer ${authService.getAccessToken()}` },
  }
  const request = axios.put('/profile', update, config)

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: UPDATE_PROFILE,
        payload: { user: response.data },
      })
    )
  }
}

export const changePassword = (params) =>
  new Promise((resolve, reject) => {
    const config = {
      headers: { Authorization: `Bearer ${authService.getAccessToken()}` },
    }
    axios
      .patch('/profile/change_password', params, config)
      .then((response) => {
        if (response.status === 200) {
          resolve(response)
        } else {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
