/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import { useLocation, matchPath } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles,
} from '@material-ui/core'
import {
  Shield as ShieldIcon,
  PieChart as PieChartIcon,
  Users as UsersIcon,
  Columns as ColumnsIcon,
  TrendingUp as TrendingUpIcon,
  Activity as ActivityIcon,
  // Gift as GiftIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
} from 'react-feather'
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined'
import ShoppingIcon from '@material-ui/icons/ShoppingCartOutlined'
import LocationIcon from '@material-ui/icons/LocationOnOutlined'
import RechargerIcon from '@material-ui/icons/AssignmentIndOutlined'
import RechargeIcon from '@material-ui/icons/PhoneIphoneOutlined'
import LogoVertical from 'src/components/LogoVertical'
import NavItem from './NavItem'

const navConfig = [
  {
    subheader: 'Reports',
    items: [
      {
        title: 'Dashboard',
        icon: PieChartIcon,
        href: '/reports/dashboard',
      },
      {
        title: 'Config',
        icon: SettingsIcon,
        href: '/reports/config',
      },
      {
        title: 'List Provinces',
        icon: LocationIcon,
        href: '/provinces/',
      },
    ],
  },
  {
    subheader: 'Management',
    items: [
      {
        title: 'Customers',
        icon: UsersIcon,
        href: '/customers',
        items: [
          {
            title: 'List Customers',
            href: '/customers',
          },
          {
            title: 'Create Customer',
            href: '/customers/create',
          },
        ],
      },
      {
        title: 'Role',
        icon: ShieldIcon,
        href: '/roles',
        items: [
          {
            title: 'List Roles',
            href: '/roles',
          },
          {
            title: 'Create Role',
            href: '/roles/create',
          },
        ],
      },
      {
        title: 'Categories',
        icon: ColumnsIcon,
        href: '/categories',
        items: [
          {
            title: 'List Categories',
            href: '/categories',
          },
          {
            title: 'Create Category',
            href: '/categories/create',
          },
        ],
      },
      {
        title: 'Ads',
        icon: TrendingUpIcon,
        href: '/ads',
        items: [
          {
            title: 'List Ads',
            href: '/ads',
          },
          {
            title: 'List Comments',
            href: '/ads/comments',
          },
          {
            title: 'List Payments',
            href: '/ads/payments',
          },
        ],
      },
      {
        title: 'Plans',
        icon: ActivityIcon,
        href: '/plans',
        items: [
          {
            title: 'List Plans',
            href: '/plans',
          },
          {
            title: 'Create Plan',
            href: '/plans/create',
          },
        ],
      },
      {
        title: 'Products',
        icon: ShoppingIcon,
        href: '/shop',
        items: [
          {
            title: 'Categories',
            href: '/shop/categories',
          },
          {
            title: 'List Products',
            href: '/shop/products',
          },
          {
            title: 'Orders',
            href: '/shop/orders',
          },
          {
            title: 'Payments',
            href: '/shop/payments',
          },
          {
            title: 'Testimonials',
            href: '/shop/testimonials',
          },
        ],
      },
    ],
  },
  {
    subheader: 'Recharges',
    items: [
      {
        title: 'Packages',
        icon: ShoppingBagIcon,
        href: '/packages',
        items: [
          {
            title: 'List Packages',
            href: '/packages',
          },
          {
            title: 'Create Package',
            href: '/packages/create',
          },
        ],
      },
      {
        title: 'Payment Gateways',
        icon: PaymentOutlinedIcon,
        href: '/payment-gateways',
        items: [
          {
            title: 'List Payment Gateways',
            href: '/payment-gateways',
          },
          {
            title: 'Create Payment Gateway',
            href: '/payment-gateways/create',
          },
        ],
      },
      {
        title: 'Recharges',
        icon: RechargeIcon,
        href: '/recharges',
        items: [
          {
            title: 'Promotions',
            href: '/recharges/promotions',
          },
          {
            title: 'Create Promotion',
            href: '/recharges/promotions/create',
          },
          {
            title: 'Subscriptions',
            href: '/recharges/subscriptions',
          },
          {
            title: 'Testimonials',
            href: '/recharges/testimonials',
          },
          {
            title: 'List Recharges',
            href: '/recharges/show',
          },
        ],
      },
      {
        title: 'Rechargers',
        icon: RechargerIcon,
        href: '/rechargers',
        items: [
          {
            title: 'List Promotions',
            href: '/promotions',
          },
          {
            title: 'Create Promotion',
            href: '/promotions/create',
          },
          {
            title: 'List Recharges',
            href: '/rechargers',
          },
        ],
      },
    ],
  },
]

function renderNavItems(tran, { items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes(tran, { acc, item, ...rest }),
        []
      )}
    </List>
  )
}

function reduceChildRoutes(tran, { acc, pathname, item, depth = 0 }) {
  const key = item.title + depth

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    })

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={tran(item.title)}
      >
        {renderNavItems(tran, {
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    )
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={tran(item.title)}
      />
    )
  }

  return acc
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
}))

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles()
  const location = useLocation()
  const { user } = useSelector((state) => state.account)
  const { t } = useTranslation('navbar')

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
    // eslint-disable-next-line
  }, [location.pathname])

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <LogoVertical height={60} />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/account">
              <Avatar alt="User" className={classes.avatar} src={user.avatar} />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user.fullname}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {user.bio}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig.map((config) => (
            <List
              key={config.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {t(config.subheader)}
                </ListSubheader>
              }
            >
              {renderNavItems(t, {
                items: config.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  )

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  )
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
}

export default NavBar
