import axios from 'axios'

const headers = {
  'Content-Type': 'application/json',
}

const instance = axios.create({
  baseURL: 'https://api.users.detooo.com/v1/',
  // baseURL: 'http://localhost:8000',
  headers,
})

export const villaAzulApi = axios.create({
  baseURL: 'https://api.mgr.villazul.detooo.com/v1',
  // baseURL: 'http://localhost:8000',
  headers,
})

export const rechargesApi = axios.create({
  baseURL: 'https://api.recargas.detooo.com/v1/',
  // baseURL: 'http://localhost:8000',
  headers,
})

export const rechargesApiV2 = axios.create({
  baseURL: 'https://api.recargas.detooo.com/v2/',
  headers,
})

export const shopApi = axios.create({
  baseURL: 'https://api.shop.detooo.com/v1/',
  // baseURL: 'http://localhost:8000',
  headers,
})

instance.CancelToken = axios.CancelToken
instance.isCancel = axios.isCancel
villaAzulApi.CancelToken = axios.CancelToken
villaAzulApi.isCancel = axios.isCancel
rechargesApi.CancelToken = axios.CancelToken
rechargesApi.isCancel = axios.isCancel
rechargesApiV2.CancelToken = axios.CancelToken
rechargesApiV2.isCancel = axios.isCancel
shopApi.CancelToken = axios.CancelToken
shopApi.isCancel = axios.isCancel

export default instance
