import 'react-perfect-scrollbar/dist/css/styles.css'
import 'react-quill/dist/quill.snow.css'
import 'nprogress/nprogress.css'
import { enableES5 } from 'immer'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import * as serviceWorker from 'src/serviceWorker'
import { SettingsProvider } from 'src/context/SettingsContext'
import { configureStore } from 'src/store'
import { restoreSettings } from 'src/utils/settings'
import App from 'src/App'
import { I18nextProvider } from 'react-i18next'
import i18n from 'src/i18n'

enableES5()

const store = configureStore()
const settings = restoreSettings()

ReactDOM.render(
  <Provider store={store}>
    <SettingsProvider settings={settings}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </SettingsProvider>
  </Provider>,
  document.getElementById('root')
)

serviceWorker.unregister()
