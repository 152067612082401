/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
import DashboardLayout from 'src/layouts/DashboardLayout'
import LoadingScreen from 'src/components/LoadingScreen'
import AuthGuard from 'src/components/AuthGuard'
import GuestGuard from 'src/components/GuestGuard'

const routesConfig = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView')),
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView')),
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView')),
  },
  {
    path: '/',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/reports/dashboard" />,
      },
      {
        exact: true,
        path: '/account',
        component: lazy(() => import('src/views/pages/AccountView')),
      },
      {
        exact: true,
        path: '/reports/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView')),
      },
      {
        exact: true,
        path: '/reports',
        component: () => <Redirect to="/reports/dashboard" />,
      },
      {
        exact: true,
        path: '/reports/config',
        component: lazy(() => import('src/views/pages/ConfigView')),
      },
      {
        exact: true,
        path: '/customers',
        component: lazy(() =>
          import('src/views/management/customer/CustomerListView')
        ),
      },
      {
        exact: true,
        path: '/customers/create',
        component: lazy(() =>
          import('src/views/management/customer/CustomerCreateView')
        ),
      },
      {
        exact: true,
        path: '/customers/:customerId',
        component: lazy(() =>
          import('src/views/management/customer/CustomerDetailsView')
        ),
      },
      {
        exact: true,
        path: '/customers/:customerId/edit',
        component: lazy(() =>
          import('src/views/management/customer/CustomerEditView')
        ),
      },
      {
        exact: true,
        path: '/roles',
        component: lazy(() => import('src/views/management/role/RoleListView')),
      },
      {
        exact: true,
        path: '/roles/create',
        component: lazy(() =>
          import('src/views/management/role/RoleCreateView')
        ),
      },
      {
        exact: true,
        path: '/roles/:roleId',
        component: lazy(() =>
          import('src/views/management/role/RoleDetailsView')
        ),
      },
      {
        exact: true,
        path: '/roles/:roleId/edit',
        component: lazy(() => import('src/views/management/role/RoleEditView')),
      },
      {
        exact: true,
        path: '/categories',
        component: lazy(() =>
          import('src/views/management/category/CategoryListView')
        ),
      },
      {
        exact: true,
        path: '/categories/create',
        component: lazy(() =>
          import('src/views/management/category/CategoryCreateView')
        ),
      },
      {
        exact: true,
        path: '/categories/:categoryId',
        component: lazy(() =>
          import('src/views/management/category/CategoryDetailsView')
        ),
      },
      {
        exact: true,
        path: '/categories/:categoryId/edit',
        component: lazy(() =>
          import('src/views/management/category/CategoryEditView')
        ),
      },
      {
        exact: true,
        path: '/ads',
        component: lazy(() => import('src/views/management/ad/AdListView')),
      },
      {
        exact: true,
        path: '/ads/comments',
        component: lazy(() =>
          import('src/views/management/comment/CommentListView')
        ),
      },
      {
        exact: true,
        path: '/ads/payments',
        component: lazy(() =>
          import('src/views/management/payment/PaymentListView')
        ),
      },
      {
        exact: true,
        path: '/ads/:adId',
        component: lazy(() => import('src/views/management/ad/AdDetailsView')),
      },
      {
        exact: true,
        path: '/ads/:adId/edit',
        component: lazy(() => import('src/views/management/ad/AdEditView')),
      },
      {
        exact: true,
        path: '/ads/comments/:commentId',
        component: lazy(() =>
          import('src/views/management/comment/CommentDetailsView')
        ),
      },
      {
        exact: true,
        path: '/ads/payments/:paymentId',
        component: lazy(() =>
          import('src/views/management/payment/PaymentDetailsView')
        ),
      },
      {
        exact: true,
        path: '/plans',
        component: lazy(() => import('src/views/management/plan/PlanListView')),
      },
      {
        exact: true,
        path: '/plans/create',
        component: lazy(() =>
          import('src/views/management/plan/PlanCreateView')
        ),
      },
      {
        exact: true,
        path: '/plans/:planId',
        component: lazy(() =>
          import('src/views/management/plan/PlanDetailsView')
        ),
      },
      {
        exact: true,
        path: '/plans/:planId/edit',
        component: lazy(() => import('src/views/management/plan/PlanEditView')),
      },
      {
        exact: true,
        path: '/shop/products/create',
        component: lazy(() =>
          import('src/views/management/shop/ShopProductCreateView')
        ),
      },
      {
        exact: true,
        path: '/shop/products',
        component: lazy(() =>
          import('src/views/management/shop/ShopProductListView')
        ),
      },
      {
        exact: true,
        path: '/shop/testimonials',
        component: lazy(() =>
          import('src/views/management/testimonials/TestimonialsListView')
        ),
      },
      {
        exact: true,
        path: '/shop/testimonials/:testimonyId',
        component: lazy(() =>
          import('src/views/management/testimonials/TestimonyDetailsView')
        ),
      },
      {
        exact: true,
        path: '/shop/payments',
        component: lazy(() =>
          import('src/views/management/shop/ShopPaymentListView')
        ),
      },
      {
        exact: true,
        path: '/shop/products/:productId',
        component: lazy(() =>
          import('src/views/management/shop/ShopProductDetailsView')
        ),
      },
      {
        exact: true,
        path: '/shop/payments/:paymentId',
        component: lazy(() =>
          import('src/views/management/shop/ShopPaymentDetailsView')
        ),
      },
      {
        exact: true,
        path: '/shop/categories/create',
        component: lazy(() =>
          import('src/views/management/shop/ShopCategoryCreateView')
        ),
      },
      {
        exact: true,
        path: '/shop/products/:productId/edit',
        component: lazy(() =>
          import('src/views/management/shop/ShopProductEditView')
        ),
      },
      {
        exact: true,
        path: '/shop/categories',
        component: lazy(() =>
          import('src/views/management/shop/ShopCategoryListView')
        ),
      },
      {
        exact: true,
        path: '/shop/categories/:categoryId',
        component: lazy(() =>
          import('src/views/management/shop/ShopCategoryDetailsView')
        ),
      },
      {
        exact: true,
        path: '/shop/categories/:categoryId/edit',
        component: lazy(() =>
          import('src/views/management/shop/ShopCategoryEditView')
        ),
      },
      {
        exact: true,
        path: '/categories/create',
        component: lazy(() =>
          import('src/views/management/shop/ShopCategoryCreateView')
        ),
      },
      {
        exact: true,
        path: '/shop/orders',
        component: lazy(() =>
          import('src/views/management/shop/ShopOrderListView')
        ),
      },
      {
        exact: true,
        path: '/shop/orders/:orderId',
        component: lazy(() =>
          import('src/views/management/shop/ShopOrderDetailsView')
        ),
      },
      {
        exact: true,
        path: '/provinces',
        component: lazy(() => import('src/views/management/ProvinceListView')),
      },
      {
        exact: true,
        path: '/provinces/:id/:name',
        component: lazy(() =>
          import('src/views/management/ProvinceDetailsView')
        ),
      },
      {
        exact: true,
        path: '/payment-gateways',
        component: lazy(() =>
          import('src/views/management/gateway/GatewayListView')
        ),
      },
      {
        exact: true,
        path: '/payment-gateways/create',
        component: lazy(() =>
          import('src/views/management/gateway/GatewayCreateView')
        ),
      },
      {
        exact: true,
        path: '/payment-gateways/:gatewayId',
        component: lazy(() =>
          import('src/views/management/gateway/GatewayDetailsView')
        ),
      },
      {
        exact: true,
        path: '/payment-gateways/:gatewayId/edit',
        component: lazy(() =>
          import('src/views/management/gateway/GatewayEditView')
        ),
      },
      {
        exact: true,
        path: '/packages',
        component: lazy(() =>
          import('src/views/management/package/PackageListView')
        ),
      },
      {
        exact: true,
        path: '/packages/create',
        component: lazy(() =>
          import('src/views/management/package/PackageCreateView')
        ),
      },
      {
        exact: true,
        path: '/packages/:packageId',
        component: lazy(() =>
          import('src/views/management/package/PackageDetailsView')
        ),
      },
      {
        exact: true,
        path: '/packages/:packageId/edit',
        component: lazy(() =>
          import('src/views/management/package/PackageEditView')
        ),
      },
      {
        exact: true,
        path: '/promotions',
        component: lazy(() =>
          import('src/views/management/promotion/PromotionListView')
        ),
      },
      {
        exact: true,
        path: '/promotions/create',
        component: lazy(() =>
          import('src/views/management/promotion/PromotionCreateView')
        ),
      },
      {
        exact: true,
        path: '/promotions/:promotionId',
        component: lazy(() =>
          import('src/views/management/promotion/PromotionDetailsView')
        ),
      },
      {
        exact: true,
        path: '/promotions/:promotionId/edit',
        component: lazy(() =>
          import('src/views/management/promotion/PromotionEditView')
        ),
      },
      {
        exact: true,
        path: '/recharges/show',
        component: lazy(() =>
          import('src/views/management/recharge/RechargeListView')
        ),
      },
      {
        exact: true,
        path: '/recharges/show/:rechargeId',
        component: lazy(() =>
          import('src/views/management/recharge/RechargeDetailsView')
        ),
      },
      {
        exact: true,
        path: '/recharges/subscriptions',
        component: lazy(() =>
          import('src/views/management/recharge/SubscriptionListView')
        ),
      },
      {
        exact: true,
        path: '/recharges/testimonials',
        component: lazy(() =>
          import('src/views/management/testimonials/TestimonialsListView')
        ),
      },
      {
        exact: true,
        path: '/recharges/testimonials/:testimonyId',
        component: lazy(() =>
          import('src/views/management/testimonials/TestimonyDetailsView')
        ),
      },
      {
        exact: true,
        path: '/recharges/promotions',
        component: lazy(() =>
          import('src/views/management/recharge/PromotionListView')
        ),
      },
      {
        exact: true,
        path: '/recharges/promotions/create',
        component: lazy(() =>
          import('src/views/management/recharge/PromotionCreateView')
        ),
      },
      {
        exact: true,
        path: '/recharges/promotions/:promotionId',
        component: lazy(() =>
          import('src/views/management/recharge/PromotionDetailsView')
        ),
      },
      {
        exact: true,
        path: '/recharges/promotions/:promotionId/edit',
        component: lazy(() =>
          import('src/views/management/recharge/PromotionEditView')
        ),
      },
      {
        exact: true,
        path: '/rechargers',
        component: lazy(() =>
          import('src/views/management/recharger/RechargeListView')
        ),
      },
      {
        exact: true,
        path: '/rechargers/:rechargeId',
        component: lazy(() =>
          import('src/views/management/recharger/RechargeDetailsView')
        ),
      },
      {
        exact: true,
        path: '/management',
        component: () => <Redirect to="/customers" />,
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
]

const renderRoutes = (routes) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment
          const Layout = route.layout || Fragment
          const Component = route.component

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          )
        })}
      </Switch>
    </Suspense>
  ) : null

function Routes() {
  return renderRoutes(routesConfig)
}

export default Routes
